var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = not;

function not(f) {
  return negation;

  function negation() {
    return !f.apply(this || _global, arguments);
  }
}

export default exports;